<script>
    import { Bar } from "vue-chartjs"; 

    export default {
        extends: Bar,
        props: {
            label: {
                type: String
            },
            chartData: {
                type: Array
            },
            options: {
                type: Object
            }, 
            chartColors: {
                type: Object
            }
        },
        mounted() {
            const aux = this.chartData.map(d => d).reverse();
            const producto = this.chartData.map(d => d.nombre).reverse();
            const dates = this.chartData.map(d => d.abre).reverse();
            const totals = this.chartData.map(d => d.total).reverse();
            
            var arrMuestra = [];
            const {
                borderColor,
                pointBorderColor,
                pointBackgroundColor,
                backgroundColor,
            } = this.chartColors;
            for (let i = 0; i < aux.length; i++) {
                let cant = [];
                for (let j = 0; j < i; j++) {
                    cant[j] = 0;
                }
                cant[i] =  aux[i].total;
                let data = {
                    label: aux[i].abre,//this.labels,// ['producto01','producto02'],// this.labels,
                    data: cant, 
                    borderColor: borderColor,
                    pointBorderColor: pointBorderColor,
                    pointBackgroundColor: pointBackgroundColor,
                    backgroundColor: backgroundColor[i],
                }
                arrMuestra.push(data);
            }
            console.log(arrMuestra)

            
            
            this.renderChart(
            {
                labels: dates,
                datasets: arrMuestra,
                option: {
                    scales: {
                    yAxes: [{
                        stacked: false
                    }],
                    xAxes: [{
                        stacked: true
                    }]
                }
                }
            },
            this.options
            );
        }
    };
</script>
