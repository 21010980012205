<script>
    import { Line } from "vue-chartjs";

    export default {
        extends: Line,
        props: {
            label: {
                type: String
            },
            chartData: {
                type: Array
            },
            options: {
                type: Object
            },
            chartColors: {
                type: Object
            }
        },
        mounted() {
            const dates = this.chartData.map(d => d.abre).reverse();
            const totals = this.chartData.map(d => d.total).reverse();
            console.log(this.chartData);
            const {
                borderColor,
                pointBorderColor,
                pointBackgroundColor,
                backgroundColor
            } = this.chartColors;

            this.renderChart(
            {
                labels: dates,
                datasets: [
                    {
                        label: this.labels,
                        data: totals,
                        borderColor: "#708DBD",//borderColor,
                        pointBorderColor: pointBorderColor,
                        pointBackgroundColor: pointBackgroundColor,
                        backgroundColor: "#708DBD"//backgroundColor
                    }
                ],
            },
            this.options
            );
        },
        methods: {
        },
        created () {
        }
    };
</script>
